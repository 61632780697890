
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    MultiSelect,
  },
  props: ["data"],
  setup(props, { emit }) {
    const shippingTypes: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.SHIPPINGTYPE_LIST);
    const shippingTypeList = computed(
      () => store.state.ShippingTypeModule.shippingType.value || []
    );

    const onChange = (args) => {
      emit("changeShippingType", args);
    };
    return { shippingTypes, store, shippingTypeList, onChange };
  },
};
