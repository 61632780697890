<template>
  <Modal
    @close="onChange"
    :modalActive="isActive"
    title="Sipariş Raporu"
    width="100vw"
  >
    <div class="p-grid p-fluid">
      <div class="col-md-12">
        <button
          id="performance-report_submit"
          ref="submitButtonSettings"
          class="btn btn-primary"
          style="margin-bottom: 5px; width: 100px"
          @click="print"
        >
          <i class="pi pi-print" style="font-size: 2rem"></i>
        </button>
        <div class="print marginTop-20">
          <table
            class="table align-middle table-striped reports table-row-dashed table-bordered table-sm marginTop-20"
          >
            <!--begi
                    .value"n::Table head-->

            <thead>
              <tr>
                <th
                  colspan="21"
                  class="text-center"
                  style="
                    background-color: #e9ecef;
                    text-align: center;
                    width: 100%;
                  "
                >
                  Sipariş Raporu
                </th>
              </tr>
              <tr class="text-start text-muted fw-bolder fs-7 gs-0">
                <th class="min-w-80px text-center">Sipariş</th>
                <th class="min-w-70px">Tarih</th>
                <th class="min-w-100px">Müşteri</th>
                <th class="min-w-70px">Departman</th>
                <th class="min-w-100px">Üretici</th>
                <th class="min-w-100px">Model Kodu</th>
                <th class="min-w-40px small-70-print">Artikel</th>
                <th class="min-w-100px">Order</th>
                <th class="min-w-40px small-70-print">öko-Tex</th>
                <th class="min-w-70px">Sezon</th>
                <th class="min-w-70px">Marka</th>
                <th class="min-w-70px">Sipariş Şekli</th>
                <th class="min-w-70px">Kumaş Onayı</th>
                <th class="min-w-70px">Yükleme Şekli</th>
                <th class="min-w-70px small-70-print">Yük. Tarihi</th>
                <th class="min-w-70px">Varış Tarihi</th>
                <th class="min-w-40px text-end small-60-print">Adet</th>
                <th class="min-w-100px text-end">Fiyat</th>
                <th class="min-w-100px text-end">Toplam Tutar</th>
                <th class="min-w-80px text-end">Sevk Edilen</th>
                <th
                  class="min-w-90px text-end small-70-print"
                  style="padding-right: 8px"
                >
                  Sevk Tutarı
                </th>
              </tr>
            </thead>
            <!--end::Table head-->

            <!--begin::Table body-->
            <tbody class="text-gray-600">
              <tr
                v-for="item in data"
                class="odd small-font-size"
                :key="item.orderId"
              >
                <td class="text-center">
                  {{ item.orderId }}
                </td>
                <td>
                  {{ moment(item.orderDate).format("DD.MM.YYYY") }}
                </td>
                <td>
                  {{ item.customerName }}
                </td>
                <td>
                  {{ item.department }}
                </td>
                <td>
                  {{ item.supplierName }}
                </td>
                <td>{{ item.modelCode }}</td>
                <td>
                  {{ item.articleNumber }}
                </td>
                <td>
                  {{ item.order }}
                </td>

                <td v-if="item.oekoTex" class="small-70-print">Var</td>
                <td v-else class="small-70-print">Yok</td>

                <td>{{ item.seasonName }}</td>
                <td>{{ item.brandName || "-" }}</td>
                <td>{{ item.orderTypeName || "-" }}</td>
                <td>
                  {{
                    item.fabricApproval
                      ? moment(item.fabricApproval).format("DD.MM.YYYY")
                      : "-"
                  }}
                </td>
                <td>{{ item.shippingTypeName || "-" }}</td>
                <td>{{ moment(item.shippingDate).format("DD.MM.YYYY") }}</td>
                <td>
                  {{
                    moment(item.shipdeliveryDatepigDate).format("DD.MM.YYYY")
                  }}
                </td>
                <td class="text-end">{{ item.orderQuantity }}</td>
                <td class="text-end">
                  {{
                    parseFloat(item.orderLinePrice)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
                      .replace(/\.(\d+)$/, ",$1")
                  }}
                  {{ currencyType }}
                </td>
                <td class="text-end">
                  {{
                    parseFloat(item.totalPrice)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
                      .replace(/\.(\d+)$/, ",$1")
                  }}
                  {{ currencyType }}
                </td>
                <td class="text-end">{{ item.shipped }}</td>
                <td class="text-end" style="padding-right: 10px">
                  {{
                    parseFloat(item.deliveryTotalPrice)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
                      .replace(/\.(\d+)$/, ",$1")
                  }}
                  {{ currencyType }}
                </td>
              </tr>

              <tr>
                <td colspan="16"></td>
                <td class="text-end">
                  {{
                    data
                      .map((f) => f.orderQuantity)
                      .reduce(function (a, b) {
                        return a + b;
                      }, 0)
                  }}
                </td>
                <td></td>
                <td class="text-end">
                  {{
                    data
                      .map((f) => f.totalPrice)
                      .reduce(function (a, b) {
                        return a + b;
                      }, 0)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
                      .replace(/\.(\d+)$/, ",$1")
                      .toString()
                  }}
                  {{ currencyType }}
                </td>
                <td class="text-end">
                  {{
                    data
                      .map((f) => f.shipped)
                      .reduce(function (a, b) {
                        return a + b;
                      }, 0)
                  }}
                </td>
                <td class="text-end small-font-size">
                  {{
                    data
                      .map((f) => f.deliveryTotalPrice)
                      .reduce(function (a, b) {
                        return a + b;
                      }, 0)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
                      .replace(/\.(\d+)$/, ",$1")
                      .toString()
                  }}
                  {{ currencyType }}
                </td>
              </tr>
            </tbody>
            <!--end::Table body-->
          </table>
        </div>
      </div>
    </div>

    <template #footer> </template>
  </Modal>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Field } from "vee-validate";
import Modal from "@/components/Modal.vue";
import { useToast } from "primevue/usetoast";
import moment from "moment";

export default {
  name: "PrintReport",
  components: {
    Modal,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    currencyType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      updateButtonDisabled: false,
      yetki: null,
      moment,
    };
  },
  methods: {
    onChange(args) {
      this.$emit("changeReceipt", args);
    },
    print() {
      var panel = document.getElementsByClassName("print")[0];
      var printWindow = window.open("", "", "");
      if (printWindow && printWindow.document) {
        printWindow.document.write(
          "<html><head><link rel='stylesheet' href='style/print.css'><title>Sipariş Raporu</title>"
        );
        printWindow.document.write("</head><body >");
        printWindow.document.write(panel.innerHTML);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        setTimeout(function () {
          printWindow.print();
          printWindow.close();
        }, 500);
      }

      return false;
    },
  },
  mounted() {
    this.store = useStore();
    this.toast = useToast();
  },
};
</script>

<style></style>
